import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Wrapper from './wrapper'

console.groupCollapsed(
  '%c🌴 Site Credits 🌴',
  'display:block;padding:0.125em 1em;font-family:courier;font-size:14px;font-weight:bold;line-height:2;text-transform:uppercase;background:white;color:black;'
)
console.log(
  '%cDesign and Development by Alright Studio \n– https://alright.studio',
  'display:block;font-family:courier;font-size:12px;font-weight:bold;line-height:1;color:green;'
)
console.groupEnd()

const duration = 0.25

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration * 2,
      when: 'afterChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: duration }
  }
}

const Layout = ({ children, location }) => {
  return (
    <Wrapper>
      <AnimatePresence
        initial={false}
        exitBeforeEnter
        onExitComplete={() => {
          window.scrollTo(0, 0)
        }}
      >
        <motion.div
          id="page"
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </Wrapper>
  )
}

export default Layout
